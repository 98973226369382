.viewDiv {
  width: 100%;
  height: 70vh;
  position: relative;
  z-index: 100;
  margin-top: 24px;
}

.esri-ui-bottom-right {
  flex-flow: column-reverse;
}

.esri-ui-bottom-right .esri-component {
  margin-top: 10px;
}